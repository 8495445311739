
/* body::-webkit-scrollbar {
    width: 0px;
} */
.ovh {
    overflow: hidden;
}
.relative {
    position: relative;
}
.text-align-left {
    text-align: left;
}
.text-align-right {
    text-align: right;
}
.mb-20 {
    margin-bottom: 20px;
}
.btn {
    display: inline-flex;
    justify-content: center;
    padding: 19px 48px 17px;
    border-radius: 1000px;
    font-size: 16px;
    font-weight: 600;
    transition: 0.3s all ease-in-out;
    position: relative;
    overflow: hidden;
}
.btn.btn-sm {
    font-size: 16px;
    font-weight: 600;
    border-radius: 1000px;
    padding: 11px 39px;
}
.btn.btn-blue {
    background: #164361;
    color: #fff;
}
.btn.btn-white {
    background: #fff;
    color: #010101;
}
.btn.btn-green {
    background: #51882E;
    color: #fff;
}
.btn.btn-brown {
    background: #412214;
    color: #fff;
}
.btn.btn-light-blue {
    background: #4D7794;
    color: #fff;
}
.btn span {
    z-index: 5;
    position: relative;
    transition: 0.3s all ease-in-out;
}
/* .btn::after,
.btn::before {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
}
.btn::before {
    transform: translateX(-100%);
    z-index: 1;
} */
.btn:hover:before {
    transform: translateX(0);
    transition: transform 350ms ease;
}
/* .btn::after {
    z-index: 0;
    transform: translateX(100%);
    transition: none;
    transition: transform 350ms ease;
}
.btn:hover:after {
    opacity: 1;
    transform: translateX(0);
    transition: transform 350ms 360ms ease;
} */
.btn:hover span {
    color: #fff;
}
/* .btn.btn-blue::after,
.btn.btn-blue::before,
.btn.btn-brown::after,
.btn.btn-brown::before {
    background: #51882E;
}
.btn.btn-white::after,
.btn.btn-white::before,
.btn.btn-light-blue::after,
.btn.btn-light-blue::before,
.btn.btn-green::after,
.btn.btn-green::before {
    background: #164361;
} */
.btn.btn-blue:hover,
.btn.btn-brown:hover {
    box-shadow: inset 0 0 0 50px #51882E;
}
.btn.btn-white:hover,
.btn.btn-light-blue:hover,
.btn.btn-green:hover {
    box-shadow: inset 0 0 0 50px #412214;
    background: none;
}
.main-container {
    padding-top: 66px;
}
.header {
    width: 100%;
    min-height: 66px;
    height: 66px;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #CCCCCC;
    position: fixed;
    top: 0;
    z-index: 9999;
    background: #fff;
}
.header-containter {
    display: flex;
    align-items: center;
    max-width: 1380px;
    width: 100%;
    margin: 0 auto;
}
.header_logo {
    display: flex;
    align-items: center;
    flex: 1;
}
.header_logo svg {
    max-width: 100%;
}
.iso-badge {
    /* display: flex;
    align-items: center; */
    background: #F2F4F6;
    border-radius: 6px;
    padding: 5px 10px;
    padding-right: 20px;
    font-size: 13px;
    color: #164361;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
}
.iso-badge svg {
    margin-right: 8px;
}
.header .iso-badge {
    margin-left: 12px;
    margin-right: 52px;
}
.header_nav {
    margin-left: auto;
}
.header_nav ul {
    display: flex;
    align-items: center;
}
.header_nav ul li+li {
    margin-left: 50px;
}
.header_nav ul li a {
    font-size: 16px;
    font-weight: 500;
    color: #164361;
    position: relative;
    display: inline-block;
    font-smooth: always;
    -webkit-font-smoothing: antialiased;
}
.header_nav ul li a.active:not(.btn):after,
.footer-nav ul li a.active:not(.btn):after {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 1px;
    background: #164361;
}
.footer {
    padding: 98px 15px 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.footer-nav ul {
    display: flex;
    align-items: center;
}
.footer-nav ul li+li {
    margin-left: 90px;
}
.footer-nav ul li a {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    color: #164361;
    /* letter-spacing: -0.29px; */
    position: relative;
}
.header_nav ul li a:not(.btn):hover,
.footer-nav ul li a:hover {
    color: #51882E;
}
.header_nav ul li a:not(.btn):not(.active):after,
.footer-nav ul li a:not(.btn):not(.active):after {
    opacity: 0;
    visibility: hidden;
    content: "";
    position: absolute;
    bottom: -3px;
    width: 0%;
    left: 0;
    height: 1px;
    background: #51882E;
    transition: 0.15s all ease-in-out;
}
.header_nav ul li a:not(.btn):not(.active):hover:after,
.footer-nav ul li a:not(.btn):not(.active):hover:after {
    opacity: 1;
    visibility: visible;
    width: 100%;
}
.footer-copyr {
    margin-top: 105px;
    font-size: 14px;
    color: #164361;
    font-weight: 500;
    /* letter-spacing: -0.3px; */
}
.home-first-screen {
    width: 100%;
    min-height: 856px;
    padding: 17.3vh 15px 0;
    background: url(../img/home-first-screen-bg.jpg) no-repeat;
    /* background-size: cover;
    background-position: top; */
    background-size: 103%;
    background-position: -1px -51px;
    position: relative;
    overflow: hidden;
}
.home-first-screen h1 {
    font-size: 44px;
    line-height: 51px;
    font-weight: bold;
    color: #164361;
    margin-bottom: 16px;
}
.home-first-screen .subtitle {
    font-size: 16px;
    /* letter-spacing: -0.2px; */
    color: #164361;
    font-weight: 500;
    max-width: 620px;
    margin-bottom: 26px;
}
.home-first-screen .content {
    max-width: 1242px;
    margin: 0 auto;
}
.first-screen-footer {
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    min-height: 323px;
    padding: 42px 15px 76px;
}
.first-screen-footer::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60%;
    background: #f1f8ff;
}
.first-screen-footer svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
}
.first-screen-footer .content {
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.first-screen-footer .content img {
    margin: 28px 0 42px;
}
.first-screen-footer .content h4 {
    font-size: 14px;
    color: rgba(22, 67, 97, 0.32);
    font-weight: 600;
    text-transform: uppercase;
}
.first-screen-footer .content h3 {
    font-size: 24px;
    letter-spacing: 1.8px;
    color: #164361;
    font-weight: 600;
    text-transform: uppercase;
}

/* .home-first-screen {
    max-height: initial;
    min-height: 650px;
    height: calc(100vh - 66px);
    padding: 15vh 15px 0;
}
.first-screen-footer {
    min-height: initial;
    padding: 4.5vh 15px 8.6vh;
}
.first-screen-footer .content img {
    margin: 3.223vh 0 4.9vh;
} */
.home-first-screen {
    height: calc(100vh + 55px);
    min-height: 750px;
    max-height: 1200px;
}

.home-two-section {
    position: relative;
    background: #EFF4EC;
}
.home-two-section-header {
    width: 100%;
    min-height: 260px;
    padding: 55px 0px 0;
    background: #384d62;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.home-two-section-header svg {
    width: 100%;
    z-index: 1;
    padding-top: 11px;
}
.home-two-section-header h3 {
    font-size: 40px;
    line-height: 54px;
    color: #fff;
    text-align: center;
    max-width: 970px;
    width: 100%;
    margin: 48px auto 0px;
    padding: 0 15px;
}
.home-two-section .content {
    padding: 82px 15px 86px;
    z-index: 3;
    position: relative;
}
.text-img-cols-wrpr {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
}
.home-two-section .text-img-cols-wrpr .text-col {
    padding-top: 172px;
    max-width: 504px;
    margin-right: 127px;
    margin-left: -176px;
}
.text-img-cols-wrpr .text-col {
    max-width: 492px;
    width: 100%;
    text-align: left;
    margin-right: 139px;
}
.text-img-cols-wrpr .text-col h3 {
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    color: #010101;
    margin-bottom: 3px;
    max-width: 440px;
}
.text-img-cols-wrpr .text-col p {
    font-size: 16px;
    line-height: 21px;
    color: #010101;
    margin-bottom: 34px;
    font-weight: 500;
}
.home-two-section-img-wrpr {
    padding: 10px;
    border: 2px solid #51882E;
    border-radius: 22px;
    overflow: hidden;
}
.home-two-section-img-wrpr img {
    width: 100%;
    max-width: 293px;
}
.home-section-two-black-sea-leaf-1,
.home-section-two-black-sea-leaf-2,
.home-section-two-hazelnuts {
    position: absolute;
    z-index: 2;
}
.home-section-two-black-sea-leaf-1 {
    right: -61px;
    bottom: -55px;
    max-width: 342px;
}
.home-section-two-black-sea-leaf-2 {
    left: -48px;
    bottom: 369px;
    max-width: 370px;
}
.home-section-two-hazelnuts {
    bottom: -454px;
    right: 95px;
    max-width: 124px;
}
.home-three-section {
    padding: 228px 15px 236px;
}
.home-three-section .text-img-cols-wrpr .text-col {
    margin-right: 0;
    margin-left: 96px;
    z-index: 2;
    max-width: 539px;
}
.home-three-section .text-img-cols-wrpr .img-col {
    position: relative;
    width: 34%;
    max-width: 512px;
}
.home-three-section .text-img-cols-wrpr .img-col img {
    position: absolute;
    left: -92px;
    top: 49%;
    transform: translateY(-50%);
    width: 785px;
    max-width: initial;
}
.home-three-section .text-img-cols-wrpr .text-col h3 {
    margin-bottom: 4px;
    max-width: 100%;
}
.home-three-section .text-img-cols-wrpr .text-col p {
    margin-bottom: 35px;
}
.rating-stars {
    display: flex;
    align-items: center;
}
.rating-stars li {
    display: flex;
    align-items: center;
}
.rating-stars li+li {
    margin-left: 7px;
}
.home-four-section .rating-stars {
    margin-bottom: 33px;
}
.home-four-section {
    padding: 100px 15px 84px;
    background: #F4FBFF;
}
.home-four-section .text-img-cols-wrpr .text-col {
    margin-right: 30px;
    margin-left: -3px;
    padding-top: 22px;
}
/* .home-four-section .text-img-cols-wrpr .text-col h3 {
    letter-spacing: -0.7px;
}
.home-four-section .text-img-cols-wrpr .text-col p {
    letter-spacing: -0.15px;
} */
.home-four-section .img-col {
    overflow: hidden;
    border-radius: 20px;
    margin-right: 0;
    margin-left: 0;
    transform: translateX(42px);
    max-width: 576px;
}
.home-four-section .text-img-cols-wrpr {
    align-items: center;
}
.home-five-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(#FDFCFA, #fff);
    text-align: center;
    padding: 144px 0 0;
}
.home-five-section .content {
    padding: 0 15px;
}
.home-five-section h2 {
    font-size: 84px;
    line-height: 100px;
    letter-spacing: 1.9px;
    font-weight: 800;
    background-image: url(../img/hazelnuts-zoomed-out.jpg);
    background-repeat: repeat;
    background-size: cover;
    background-position: center;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    max-width: 1185px;
    width: 100%;
    margin: 0 auto 22px;
}
.home-five-section .subtitle {
    font-size: 20px;
    line-height: 32px;
    font-weight: bold;
    background-image: url(../img/hazelnuts-zoomed-out.jpg);
    background-repeat: repeat;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    max-width: 1156px;
    width: 100%;
    margin: 0 auto 65px;
}
.home-five-section .btn {
    padding: 19px 30px 17px;
    max-width: 252px;
    width: 100%;
}
.home-five-section .get-a-free-quote {
    width: 100%;
    padding: 121px 0 47px;
    position: relative;
    text-align: center;
}
.home-five-section .get-a-free-quote h3 {
    font-size: 70px;
    font-weight: bold;
    line-height: 64px;
    letter-spacing: -0.6px;
    color: #51882E;
    margin-bottom: 11px;
}
.home-five-section .get-a-free-quote p {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: #7D8E9B;
    margin-bottom: 37px;
}
.home-five-section .get-a-free-quote .btn {
    max-width: 252px;
    width: 100%;
    justify-content: center;
}
.home-five-section .get-a-free-quote svg {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 2;
}
.home-six-section {
    background: url(../img/home-six-section-bg.jpg) no-repeat;
    background-size: cover;
    background-position: top center;
    background-attachment: fixed;
    padding: 203px 15px 83px;
    text-align: center;
    position: relative;
}
.home-six-section:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.15);
    z-index: 1;
}
.home-six-section .content {
    z-index: 2;
    position: relative;
}
.home-six-section .before-title {
    font-size: 17px;
    line-height: 22px;
    font-weight: 600;
    /* letter-spacing: -0.7px; */
    color: rgba(255, 255, 255, 0.54);
    display: inline-block;
    margin-bottom: 67px;
}
.home-six-section h3 {
    font-size: 44px;
    line-height: 54px;
    /* letter-spacing: -1.1px; */
    font-weight: 500;
    color: #fff;
    margin-bottom: 27px;
}
.home-six-section h3.text-align-right {
    /* letter-spacing: -0.6px; */
    margin-bottom: 46px;
}
.home-six-section p {
    font-size: 19px;
    line-height: 25px;
    font-weight: 500;
    color: #E0F1FF;
    text-align: center;
    max-width: 930px;
    width: 100%;
    margin: 0 auto 25px;
    letter-spacing: -0.5px;
}
.home-six-section .content {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
}
.home-seven-section {
    background: #F4FBFF;
    padding: 90px 15px 88px;
}
.home-seven-section .section-title {
    font-size: 42px;
    line-height: 54px;
    font-weight: 600;
    color: #010101;
    margin-bottom: 19px;
}
.home-seven-section .content {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}
.faq-item {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 39px 28px;
    border: 1px solid #CCCCCC;
}
.faq-item+.faq-item {
    margin-top: 25px;
}
.faq-item h3 {
    font-size: 18px;
    line-height: 22px;
    /* letter-spacing: -0.3px; */
    font-weight: 600;
    color: #010101;
    margin-bottom: 12px;
}
.faq-item p {
    font-size: 16px;
    line-height: 21px;
    /* letter-spacing: -0.14px; */
    font-weight: 400;
    color: #919191;
}
.faq-item p a {
    text-decoration: underline;
    color: #2E6589;
}
.faq-item p a:hover { 
    color: #51882E;
}
.home-seven-section .content .btn {
    margin: 42px auto 0;
    padding: 18px 64px;
}
.btn.btn-with-icon span {
    display: inline-flex;
    align-items: center;
}
.btn.btn-with-icon svg {
    margin-left: 10px;
}
.page-first-section {
    width: 100%;
    min-height: 650px;
    /* padding: 131px 103px 0; */
    position: relative;
    overflow: hidden;
}
.page-first-section h1 {
    font-size: 64px;
    line-height: 72px;
    font-weight: 600;
    color: #fff;
    max-width: 917px;
    margin-bottom: 2px;
}
.page-first-section .subtitle {
    font-size: 18px;
    color: #fff;
    font-weight: 500;
    max-width: 917px;
    margin-bottom: 17px;
}
.page-first-section .content {
    max-width: 1276px;
    margin: 0 auto;
}
.page-first-section .content .btn {
    max-width: 252px;
    width: 100%;
}
.about-us-first-section {
    background: url(../img/about-us-first-section-bg.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    padding: 188px 15px 30px;
    min-height: 700px;
}
.about-us-first-section:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.20));
}
.about-us-first-section .content {
    z-index: 2;
    position: relative;
}
.about-us-first-section.page-first-section h1 {
    margin-bottom: 10px;
}
.about-us-first-section.page-first-section .subtitle {
    margin-bottom: 37px;
}
.about-us-section-two {
    background: #F4FBFF;
    padding: 63px 15px 52px;
    position: relative;
}
.section-title-md {
    font-size: 42px;
    line-height: 54px;
    color: #164361;
    margin-bottom: 36px;
    text-align: center;
}
.about-us-section-two .content {
    z-index: 2;
    position: relative;
}
.choose-items {
    width: 100%;
    max-width: 934px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
}
.choose-items li {
    display: flex;
    align-items: center;
    width: calc(50% - 15px);
    margin-bottom: 20px;
    border-radius: 12px;
    background: #fff;
    padding: 22px 28px;
    font-size: 20px;
    font-weight: 600;
    color: #164361;
}
.choose-items li .icon-wrpr {
    width: 40px;
    height: 40px;
    margin-right: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.about-us-section-two-header {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: 1;
    background: #F4FBFF;
}
.about-us-section-two-header svg {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
}
.about-us-section-three {
    padding: 132px 15px 29px;
}
.about-us-section-three .content {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
}
.history-cards-wrpr {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.history-card-item {
    width: calc(50% - 10px);
    border-radius: 20px;
    background: #F4FBFF;
    padding: 40px 40px 35px;
    text-align: center;
    margin-bottom: 20px;
}
.history-card-item .img-wrpr {
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 23px;
}
.history-card-item .img-wrpr img {
    width: 100%;
    object-fit: cover;
}
.history-card-item h3 {
    font-size: 26px;
    font-weight: bold;
    color: #010101;
    margin-bottom: 7px;
}
.history-card-item p {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    color: #010101;
    padding: 0 18px;
}
.about-us-section-four {
    padding: 88px 15px 130px;
    text-align: center;
}
.about-us-section-four h3 {
    font-size: 70px;
    font-weight: 700;
    color: #234055;
    margin-bottom: 11px;
    line-height: 83px;
}
.about-us-section-four p {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: #7E8F9B;
    max-width: 1004px;
    margin: 0 auto 30px;
}
.about-us-section-four .btn {
    padding: 19px 34px 17px;
}
.about-us-section-five {
    background: url(../img/about-us-section-five-bg.jpg);
    background-size: cover;
    background-position: top center;
    padding: 306px 15px 60px;
    text-align: center;
}
.about-us-section-five .content {
    max-width: 937px;
    width: 100%;
    margin: 0 auto;
}
.about-us-section-five h3 {
    font-size: 46px;
    line-height: 54px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 15px;
}
.about-us-section-five p {
    font-size: 17px;
    line-height: 22px;
    font-weight: 500;
    color: #fff;
}
.about-us-section-five p+p {
    margin-top: 26px;
}
.want-price-quote-section {
    padding: 96px 15px;
    text-align: center;
    background: #F4FBFF;
    overflow: hidden;
}
.want-price-quote-section h3 {
    color: #234055;
    font-size: 20px;
    font-weight: 700;
}
.want-price-quote-section h2 {
    font-size: 70px;
    font-weight: bold;
    color: #234055;
    margin-top: 5px;
}
.want-price-quote-section p {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: #788C9B;
    margin-bottom: 51px;
}
.want-price-quote-section .btn {
    max-width: 252px;
    width: 100%;
}
.products-first-section {
    background: url(../img/products-first-section-bg.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    padding: 167px 15px 30px;
    min-height: 645px;
}
.products-first-section p {
    margin-bottom: 9px;
}
.products-first-section h1 {
    margin-bottom: 32px;
}
.page-first-section.about-us-first-section,
.page-first-section.products-first-section {
    height: calc(100vh - 155px);
    min-height: 645px;
    max-height: 900px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 167px 15px 129px;
}
.page-first-section.about-us-first-section {
    height: calc(100vh - 100px);
    padding: 105px 15px 129px;
    min-height: 701px;
}
.page-first-section.about-us-first-section .animated,
.page-first-section.products-first-section .animated {
    width: 100%;
}
.want-price-quote-section.bg-white {
    background: #fff;
}
.want-price-quote-section.have-bb {
    border-bottom: 1px solid #A3A3A3;
}
.img-border-radius-wrpr {
    overflow: hidden;
    border-radius: 15px;
}
.img-border-radius-wrpr img {
    width: 100%;
    object-fit: cover;
}
.products-section-two {
    background: #F4FBFF;
    padding: 114px 15px 70px;
}
.products-section-two .text-img-cols-wrpr {
    align-items: center;
    width: 100%;
    max-width: 1260px;
}
.products-section-two .text-img-cols-wrpr .text-col {
    max-width: 100%;
    margin-right: 53px;
}
.products-section-two .text-col h3 {
    font-weight: bold;
    max-width: 720px;
    font-size: 40px;
    line-height: 53px;
    margin-bottom: 13px;
}
.products-section-two .img-col {
    max-width: 470px;
    width: 100%;
}
.products-section-two:not(.contact-us) .img-col .img-border-radius-wrpr {
    max-width: 470px;
}
.products-section-two.contact-us .img-col .img-border-radius-wrpr {
    max-width: 581px;
}
.home-seven-section.bg-gradient {
    background: linear-gradient(#fff, #F4FBFF, #F4FBFF);
}
.products-section-three {
    padding: 117px 15px 84px;
}
.products-section-three .content {
    max-width: 1260px;
    width: 100%;
    margin: 0 auto;
}
.products-section-three .section-header {
    text-align: center;
    max-width: 906px;
    width: 100%;
    margin: 0 auto;
}
.products-section-three .section-header h3 {
    color: #412214;
    font-size: 44px;
    font-weight: bold;
    margin-bottom: 12px;
}
.products-section-three .section-header p {
    color: #412214;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 34px;
}
.product-cards-wrpr {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.product-card-item {
    width: calc(33.33% - 10px);
    display: flex;
    flex-direction: column;
    padding: 30px 30px 22px;
    background: #F7F7F7;
    border-radius: 20px;
    margin-bottom: 20px;
}
.product-card-item .img-wrpr {
    overflow: hidden;
    border-radius: 12px;
}
.product-card-item .img-wrpr img {
    width: 100%;
    object-fit: cover;
}
.product-card-item .text-wrpr {
    display: flex;
    flex-direction: column;
    padding-top: 22px;
    flex: 1;
    min-height: 297px;
}
.product-card-item .text-wrpr h3 {
    color: #010101;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 8px;
}
.product-card-item .text-wrpr p {
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    color: #5D5D5D;
    margin-bottom: 15px;
}
.product-card-item .text-wrpr .btn {
    margin: 0 auto;
    margin-top: auto;
    max-width: 252px;
    width: 100%;
}
.product-card-item .text-wrpr .or-link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 11px;
    color: #AD9083;
    font-size: 14px;
}
.product-card-item .text-wrpr .or-link a {
    color: #412214;
    margin-left: 4px;
    font-weight: 600;
}
.product-card-item .text-wrpr .or-link a:hover {
    color: #51882E;
}
.contact-us-section-one {
    background: url(../img/contact-us-section-one.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    padding: 58px 15px 34px;
    text-align: center;
}
.contact-us-section-one h2 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 12px;
}
.contact-us-section-one h1 {
    font-size: 46px;
    font-weight: 600;
    line-height: 60px;
    color: #fff;
}
.contact-us-section-two {
    padding: 42px 15px 72px;
}
.contact-us-section-two.request-form-section {
    padding: 42px 15px 122px;
}
.contact-us-section-two.request-form-section+.want-price-quote-section {
    padding-top: 55px;
}
.contact-us-section-two .content {
    max-width: 616px;
    width: 100%;
    margin: 0 auto;
}
.contact-us-section-two h3 {
    font-size: 28px;
    margin-bottom: 5px;
    color: #010101;
    font-weight: 600;
}
.contact-us-section-two p {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.24px;
    font-weight: 400;
    color: #010101;
    margin-bottom: 21px;
}
.contact-us-section-one+.contact-us-section-two p {
    width: calc(100% + 6px);
}
.inputs-two-cols {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.inputs-two-cols .form-group {
    width: calc(50% - 20px);
}
.form-group {
    margin-bottom: 22px;
}
.input-label {
    font-size: 14px;
    font-weight: 600;
    color: #010101;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    width: 100%;
}
.required-star {
    color: #FF2121;
    display: block;
    margin-left: 3px;
}
.input-text {
    border: 1px solid #A7A7A7;
    background: #fff;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #010101;
    padding: 11px 15px;
    display: block;
    width: 100%;
}
textarea.input-text {
    min-height: 100px;
    resize: none;
}
.input-text.error {
    border-color: #FF2121;
}
.contact-us-form {
    display: flex;
    flex-direction: column;
    position: relative;
}
.contact-us-form .btn {
    max-width: 252px;
    width: 100%;
    margin: 19px auto 0;
}
.contact-us-form .status-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    background: #fff;
    z-index: 2;
    flex-direction: column;
    text-align: center;
    opacity: 0;
    animation: appear 1s forwards;
}
@keyframes appear {
    to {
        opacity: 1;
    }
}
.products-section-two.contact-us-section-three {
    padding: 82px 15px 70px;
}
.contact-us-section-three .text-col .btn {
    margin-top: 34px;
}
.contact-us-section-three .text-col h3 {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
}
.contact-us-section-three .text-img-cols-wrpr .text-col p {
    font-weight: 500;
}
.contact-us.contact-us-section-three .text-col h3 {
    margin-top: 0px;
    margin-bottom: 4px;
}
.contact-us.contact-us-section-three .text-img-cols-wrpr .text-col p {
    margin-bottom: 23px;
}
.contact-us.contact-us-section-three .text-col .btn {
    margin-top: 15px;
}
.contact-us-section-three .img-col {
    max-width: 581px;
}
.contact-us.contact-us-section-three .text-img-cols-wrpr .text-col {
    margin-right: 128px;
}
.contact-us.contact-us-section-three .img-col {
    transform: translateX(7px);
}
.products-section-two.contact-us.contact-us-section-three {
    padding: 82px 15px 90px;
}
.contact-us.contact-us-section-three+.about-us-section-four {
    padding: 154px 15px 99px;
    text-align: center;
}
.contact-us-section-three .img-col p {
    text-align: center;
    margin-top: 16px;
    font-size: 16px;
    font-weight: 500;
    color: #87A1AA;
}
.contact-us-section-three .text-img-cols-wrpr .text-col {
    margin-right: 141px;
    margin-left: 59px;
    max-width: 492px;
}
.home-seven-section .subtitle {
    font-size: 16px;
    line-height: 21px;
    color: #010101;
    margin-bottom: 32px;
}
.home-seven-section .section-title.mb-12 {
    margin-bottom: 12px;
}
.requests-samples-first-section {
    background: url(../img/requests-samples-first-section-bg.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    padding: 83px 15px 78px;
    min-height: 432px;
}
.page-first-section.requests-samples-first-section p {
    margin-bottom: 16px;
}
.page-first-section.requests-samples-first-section h1 {
    max-width: 830px;
}
.optional-label-word {
    color: #C7C7C7;
    display: block;
    margin-left: auto;
}
select.input-text {
    background: #fff url(../img/arrow-down.jpg) no-repeat;
    background-position: calc(100% - 12px) 16px;
    padding: 12px 15px;
    min-height: 42px;
}
.privacy-section-one {
    padding: 62px 15px 106px;
    border-bottom: 1px solid #A3A3A3;
}
.privacy-section-one h1 {
    font-size: 42px;
    font-weight: 600;
    color: #010101;
    margin-bottom: 13px;
}
.privacy-section-one p {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color: #010101;
    margin-bottom: 21px;
}
.privacy-section-one .content {
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
}
.mobile-nav-btn {
    display: none;
}
.cookie-policy-modal {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #fff;
    border: 1px solid #CCCCCC;
    border-radius: 12px;
    z-index: 3;
    max-width: 500px;
    /* padding: 15px; */
    max-height: calc(100vh - 100px);
    overflow-y: auto;
}
.cookie-policy-modal p {
    font-size: 16px;
    font-weight: 400;
    /* margin-bottom: 15px; */
    padding: 15px 15px 0;
}
.cookie-policy-modal p a {
    text-decoration: underline;
    color: #51882E;
}
.faq-item p a:hover,
.cookie-policy-modal p a:hover {
    box-shadow: 0 0 0 0.5em transparent;
    animation: pulse 1s;
}
.cookie-policy-modal .btns-wrpr {
    display: flex;
    justify-content: flex-end;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background: #fff;
    padding: 15px;
}
.customize-content {
    margin-top: 15px;
    margin-bottom: 10px;
    padding: 15px;
}
.cookie-policy-modal .btns-wrpr .btn {
    padding: 10px 30px;
    margin-left: 10px;
}
.cookie-policy-modal .customize-content {
    margin-bottom: 30px;
}
.cookie-policy-modal .customize-content h2 {
    font-weight: bold;
    font-size: 18px;
    color: #234055;
    margin-bottom: 5px;
}
.cookie-policy-modal .customize-item+.customize-item {
    margin-top: 15px;
}
.cookie-policy-modal .customize-item h3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    color: #234055;
    margin-bottom: 5px;
}
.cookie-policy-modal .customize-item p {
    margin: 0;
    padding: 0;
}
.checkbox-item {
    cursor: pointer;
    display: block;
}
.checkbox-item input {
    display: none;
}
.checkbox-item-box {
    border: 1px solid #A7A7A7;
    border-radius: 4px;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    position: relative;
    background: #fff;
}
.checkbox-item input:checked+.checkbox-item-box {
    background: #51882E;
}
.checkbox-item input:checked+.checkbox-item-box:before {
    content: "";
    display: block;
    position: absolute;
    left: 2px;
    top: 3px;
    width: 14px;
    height: 8px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(-50deg);
}
.cookie-policy-modal .cookie-policy-customize-btn.btn {
    margin-right: auto;
    margin-left: 0;
}
.page-first-section .animate__animated {
    width: 100%;
}
.contact-us-section-two .status-overlay p {
    font-size: 15px;
}
.contact-us-section-two .status-overlay p b {
    font-weight: 600;
}
.contact-us-section-two .status-overlay a {
    text-decoration: underline;
    color: #2E6589;
}
.contact-us-section-two .status-overlay a:hover {
    color: #51882E;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0, 30%, 0);
    }
    to {
        opacity: 1;
        transform: none
    }
}
@keyframes zoomIn {
    0% {
        opacity: 0;
        transform: scale3d(.8, .8, .8);
    }
    50% {
        opacity: 1
    }
}
@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translate3d(-30%, 0, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translate3d(30%, 0, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.btn-loading-span {
    position: relative;
}

.btn-loading-active,
.btn:hover .btn-loading-active {
    color: transparent;
}

.loading {
    display: flex;
    gap: 6px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    opacity: 0;
}

.btn-loading-active .loading {
    opacity: 1;
}

.loading div {
    width: 12px;
    height: 12px;
    background: white;
    border-radius: 50%;
    animation: cycle 1s ease-in-out infinite;
}

.loading div:nth-child(1) {
    animation-delay: 0;
}

.loading div:nth-child(2) {
    animation-delay: 0.2s;
}

.loading div:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes cycle {
    0% {
        transform: translateY(-4px);
        opacity: 0;
    }

    50% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(-4px);
        opacity: 0;
    }
}

body:has(.main-not-en) .cookie-policy-modal {
    max-width: 550px;
}
body:has(.main-not-en) .footer-nav ul li+li {
    margin-left: 50px;
}
body:has(.main-not-en) .product-card-item .text-wrpr .btn {
    max-width: 100%;
    width: initial;
}
.contact-us-section-four {
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}