/* This stylesheet generated by Transfonter (https://transfonter.org) on February 10, 2018 4:03 PM */

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('BrandonGrotesque-MediumItalic.eot');
    src: local('Brandon Grotesque Medium Italic'), local('BrandonGrotesque-MediumItalic'),
        url('BrandonGrotesque-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('BrandonGrotesque-MediumItalic.woff') format('woff'),
        url('BrandonGrotesque-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('BrandonGrotesque-Bold.eot');
    src: local('Brandon Grotesque Bold'), local('BrandonGrotesque-Bold'),
        url('BrandonGrotesque-Bold.eot?#iefix') format('embedded-opentype'),
        url('BrandonGrotesque-Bold.woff') format('woff'),
        url('BrandonGrotesque-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('BrandonGrotesque-Light.eot');
    src: local('Brandon Grotesque Light'), local('BrandonGrotesque-Light'),
        url('BrandonGrotesque-Light.eot?#iefix') format('embedded-opentype'),
        url('BrandonGrotesque-Light.woff') format('woff'),
        url('BrandonGrotesque-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('BrandonGrotesque-Black.eot');
    src: local('Brandon Grotesque Black'), local('BrandonGrotesque-Black'),
        url('BrandonGrotesque-Black.eot?#iefix') format('embedded-opentype'),
        url('BrandonGrotesque-Black.woff') format('woff'),
        url('BrandonGrotesque-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('BrandonGrotesque-Medium.eot');
    src: local('Brandon Grotesque Medium'), local('BrandonGrotesque-Medium'),
        url('BrandonGrotesque-Medium.eot?#iefix') format('embedded-opentype'),
        url('BrandonGrotesque-Medium.woff') format('woff'),
        url('BrandonGrotesque-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('BrandonGrotesque-ThinItalic.eot');
    src: local('Brandon Grotesque Thin Italic'), local('BrandonGrotesque-ThinItalic'),
        url('BrandonGrotesque-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('BrandonGrotesque-ThinItalic.woff') format('woff'),
        url('BrandonGrotesque-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('BrandonGrotesque-Thin.eot');
    src: local('Brandon Grotesque Thin'), local('BrandonGrotesque-Thin'),
        url('BrandonGrotesque-Thin.eot?#iefix') format('embedded-opentype'),
        url('BrandonGrotesque-Thin.woff') format('woff'),
        url('BrandonGrotesque-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('BrandonGrotesque-LightItalic.eot');
    src: local('Brandon Grotesque Light Italic'), local('BrandonGrotesque-LightItalic'),
        url('BrandonGrotesque-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('BrandonGrotesque-LightItalic.woff') format('woff'),
        url('BrandonGrotesque-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('BrandonGrotesque-Regular.eot');
    src: local('Brandon Grotesque Regular'), local('BrandonGrotesque-Regular'),
        url('BrandonGrotesque-Regular.eot?#iefix') format('embedded-opentype'),
        url('BrandonGrotesque-Regular.woff') format('woff'),
        url('BrandonGrotesque-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('BrandonGrotesque-RegularItalic.eot');
    src: local('Brandon Grotesque Regular Italic'), local('BrandonGrotesque-RegularItalic'),
        url('BrandonGrotesque-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('BrandonGrotesque-RegularItalic.woff') format('woff'),
        url('BrandonGrotesque-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('BrandonGrotesque-BoldItalic.eot');
    src: local('Brandon Grotesque Bold Italic'), local('BrandonGrotesque-BoldItalic'),
        url('BrandonGrotesque-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('BrandonGrotesque-BoldItalic.woff') format('woff'),
        url('BrandonGrotesque-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('BrandonGrotesque-BlackItalic.eot');
    src: local('Brandon Grotesque Black Italic'), local('BrandonGrotesque-BlackItalic'),
        url('BrandonGrotesque-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('BrandonGrotesque-BlackItalic.woff') format('woff'),
        url('BrandonGrotesque-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}
