/* @media(max-width: 1400px){
    .home-three-section .text-img-cols-wrpr .img-col img {
        width: 230%;
    }
} */

@media(min-width: 1470px){
    .header_logo {
        min-width: 354px;
    }
}

@media(max-width: 1199px){
    .header .iso-badge {
        margin-left: 15px;
        margin-right: 15px;
    }
    .header_nav ul li+li {
        margin-left: 25px;
    }
    .header_nav ul li a {
        font-size: 14px;
    }
    .header_nav ul .btn.btn-sm {
        font-size: 14px;
    }
    .text-img-cols-wrpr .text-col {
        margin-right: 30px;
    }
    .home-two-section .text-img-cols-wrpr .text-col {
        margin-right: 30px;
        margin-left: 0;
    }
    .home-two-section .text-img-cols-wrpr .img-col {
        display: flex;
        align-items: center;
    }
    .home-three-section .text-img-cols-wrpr .img-col img {
        width: 690px;
    }
    .home-four-section .text-img-cols-wrpr .text-col {
        margin-left: 0;
    }
    .home-four-section .img-col {
        transform: none;
    }
    .home-section-two-hazelnuts {
        right: 0;
    }
    .home-first-screen {
        background-size: cover;
        background-position: center;
    }
    /* .home-first-screen:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.4);
    } */
    .home-first-screen .content {
        z-index: 2;
        position: relative;
    }
    .footer-nav ul li+li {
        margin-left: 30px;
    }
    .product-card-item {
        width: calc(50% - 10px);
    }
    .contact-us.contact-us-section-three .img-col {
        transform: none;
    }
}

@media(max-width: 991px){
    .text-img-cols-wrpr {
        flex-direction: column;
        width: 100%;
        align-items: center;
    }
    .text-img-cols-wrpr .img-col {
        order: 0;
    }
    .home-two-section .text-img-cols-wrpr .text-col,
    .home-three-section .text-img-cols-wrpr .text-col,
    .home-four-section .text-img-cols-wrpr .text-col,
    .text-img-cols-wrpr .text-col {
        order: 1;
        padding-top: 30px;
        margin-right: 0;
        max-width: 539px;
    }
    .home-two-section .text-img-cols-wrpr .text-col {
        padding-top: 30px;
        margin-right: 0;
    }
    .home-three-section .text-img-cols-wrpr .img-col {
        width: 100%;
    }
    .home-three-section .text-img-cols-wrpr .img-col img {
        position: initial;
        width: 100%;
        transform: none;
    }
    .home-three-section .text-img-cols-wrpr .text-col {
        margin-left: 0;
    }
    .main-container {
        padding-top: 66px;
    }
    .header {
        position: fixed;
        left: 0;
        top: 0;
        background: #fff;
        z-index: 99;
    }
    .mobile-nav-btn {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        overflow: hidden;
        height: 18px;
        width: 26px;
        min-width: 26px;
    }
    .mobile-nav-btn span {
        width: 26px;
        height: 3px;
        background: #164361;
        border-radius: 4px;
        transition: 0.15s all ease-in-out;
    }
    .mobile-nav-btn span:nth-child(2){
        margin: 4px 0;
    }
    .mobile-nav-btn.active span:nth-child(2) {
        transform: translateX(100%);
    }
    .mobile-nav-btn.active span:nth-child(1) {
        transform: rotate(35deg) translate(4px, 6px);
    }
    .mobile-nav-btn.active span:nth-child(3) {
        transform: rotate(-35deg) translate(4px, -6px);
    }
    .header_nav {
        position: fixed;
        left: 0;
        top: 66px;
        padding: 30px 15px;
        background: #fff;
        z-index: 10;
        width: 100%;
        transform: translateY(-20px);
        opacity: 0;
        visibility: hidden;
        transition: 0.15s all ease-in-out;
        border-bottom: 1px solid #CCCCCC;
    }
    .header_nav.active {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }
    .header_nav ul {
        flex-direction: column;
    }
    .header_nav ul li a {
        font-size: 16px;
    }
    .header_nav ul li+li {
        margin-left: 0;
        margin-top: 10px;
    }
    .header .iso-badge {
        display: none;
    }
    .header_logo {
        flex: initial;
        margin-right: auto;
    }
    .home-first-screen h1 br {
        display: none;
    }
    .home-three-section {
        padding: 70px 26px;
    }
    .home-section-two-hazelnuts {
        right: 20px;
    }
    .home-section-two-black-sea-leaf-1,
    .home-section-two-black-sea-leaf-2,
    .home-section-two-hazelnuts {
        opacity: 0.5;
    }
    .home-five-section {
        padding: 50px 0 0;
    }
    .home-five-section h2 {
        font-size: 60px;
        line-height: 76px;
    }
    .home-five-section .subtitle {
        margin-bottom: 30px;
    }
    .products-section-two .text-img-cols-wrpr .text-col {
        margin-right: 0;
    }
    .page-first-section.about-us-first-section,
    .about-us-first-section {
        padding-top: 50px;
        min-height: initial;
        padding-bottom: 150px;
    }
    .about-us-first-section:before {
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.50));
    }
    .page-first-section.about-us-first-section {
        height: initial;
    }
    .about-us-section-two {
        padding: 0 15px 50px;
    }
    .about-us-section-three,
    .about-us-section-four {
        padding: 50px 15px;
    }
    .about-us-section-three {
        padding-bottom: 30px;
    }
    .about-us-section-five {
        padding: 150px 15px 50px;
    }
    .history-card-item {
        padding: 15px;
    }
    .page-first-section h1 {
        font-size: 52px;
        line-height: 64px;
    }
    .requests-samples-first-section {
        min-height: initial;
    }
    .privacy-section-one {
        padding: 50px 15px;
    }
    .privacy-section-one h1 {
        font-size: 36px;
    }
    .privacy-section-one p {
        font-size: 14px;
        line-height: 18px;
    }
    .home-five-section .get-a-free-quote {
        padding-top: 100px;
    }
    .page-first-section .subtitle {
        font-size: 16px;
        margin-bottom: 15px;
    }
    .contact-us.contact-us-section-three .text-img-cols-wrpr .text-col {
        margin-right: 0;
        margin-left: 0;
    }
    .products-section-two.contact-us.contact-us-section-three,
    .contact-us.contact-us-section-three+.about-us-section-four {
        padding: 50px 15px;
    }
    .contact-us-section-one+.contact-us-section-two p {
        width: 100%;
    }
}

@media(max-width: 767px){
    .footer-nav ul {
        flex-direction: column;
    }
    body:has(.main-not-en) .footer-nav ul li+li,
    .footer-nav ul li+li {
        margin-left: 0;
        margin-top: 5px;
    }
    .footer-copyr {
        margin-top: 50px;
    }
    .footer {
        padding-top: 50px;
    }
    .home-first-screen {
        min-height: initial;
        height: initial;
        padding-top: 70px;
        padding-bottom: 300px;
    }
    .first-screen-footer {
        min-height: 200px;
        padding: 40px 15px;
    }
    .first-screen-footer .content img {
        margin: 15px 0 30px;
    }
    .home-four-section {
        padding: 50px 15px 50px;
    }
    .home-six-section {
        padding: 100px 15px 50px;
    }
    .home-six-section h3 {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 20px;
    }
    .home-six-section p {
        font-size: 16px;
        line-height: 22px;
    }
    .home-six-section .before-title {
        margin-bottom: 30px;
    }
    .home-seven-section {
        padding: 50px 15px;
    }
    .home-seven-section .section-title {
        font-size: 34px;
        line-height: 42px;
    }
    .home-five-section .get-a-free-quote h3 {
        font-size: 56px;
        line-height: 54px;
    }
    .home-section-two-black-sea-leaf-2 {
        top: 280px;
    }
    .products-first-section,
    .page-first-section.products-first-section {
        padding: 100px 15px 50px;
        min-height: initial;
        height: initial;
    }
    .page-first-section h1 {
        font-size: 44px;
        line-height: 51px;
    }
    .products-section-two {
        padding: 50px 15px;
    }
    .products-section-three {
        padding: 50px 15px;
    }
    .product-card-item {
        width: 100%;
    }
    .product-card-item .text-wrpr {
        min-height: initial;
    }
    .want-price-quote-section {
        padding: 50px 15px;
    }
    .want-price-quote-section h2 {
        font-size: 56px;
        line-height: 54px;
    }
    .want-price-quote-section p {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 30px;
    }
    .choose-items li {
        width: 100%;
    }
    .history-card-item {
        width: 100%;
    }
    .about-us-section-four h3 {
        font-size: 44px;
    }
    .about-us-section-four p {
        font-size: 16px;
        line-height: 20px;
    }
    .about-us-section-five,
    .requests-samples-first-section {
        padding: 50px 15px;
    }
    .contact-us-section-one h1 {
        font-size: 36px;
        line-height: 44px;
    }
    .contact-us-section-one {
        padding: 50px 15px 30px;
    }
}

@media(max-width: 576px){
    .home-five-section h2 {
        font-size: 34px;
        line-height: 42px;
    }
    .home-five-section .subtitle {
        font-size: 14px;
        line-height: 22px;
        margin: 0 auto 50px;
    }
    .home-two-section-header h3 {
        font-size: 26px;
        line-height: 32px;
        padding-bottom: 15px;
        margin-top: 0;
    }
    .home-two-section-header {
        min-height: initial;
        padding: 50px 0 0;
    }
    .home-two-section .content {
        padding: 50px 15px;
    }
    .first-screen-footer .content h3 {
        font-size: 13px;
        text-align: center;
    }
    .first-screen-footer {
        padding: 55px 15px 25px;
    }
    .first-screen-footer svg {
        bottom: 120px;
        top: initial;
    }
    .home-first-screen h1 {
        font-size: 30px;
        line-height: 36px;
    }
    .text-img-cols-wrpr .text-col h3 {
        font-size: 22px;
        line-height: 30px;
    }
    .text-img-cols-wrpr .text-col p {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 20px;
    }
    .home-three-section {
        padding: 50px 0;
    }
    .home-three-section .text-img-cols-wrpr .text-col {
        padding-left: 15px;
        padding-right: 15px;
    }
    .home-six-section h3 {
        font-size: 25px;
        line-height: 32px;
    }
    .faq-item {
        padding: 15px;
    }
    .home-first-screen {
        padding-top: 40px;
        padding-bottom: 250px;
    }
    .header {
        padding: 10px 15px;
    }
    .home-section-two-black-sea-leaf-1,
    .home-section-two-black-sea-leaf-2,
    .home-section-two-hazelnuts {
        opacity: 0.5;
    }
    .page-first-section h1 {
        font-size: 30px;
        line-height: 36px;
    }
    .products-section-three .section-header h3 {
        font-size: 32px;
    }
    .want-price-quote-section h2 {
        font-size: 44px;
        line-height: 48px;
    }
    .product-card-item {
        padding: 15px;
    }
    .products-first-section {
        padding-top: 50px;
    }
    .choose-items li {
        margin-bottom: 15px;
        padding: 15px 20px;
        font-size: 16px;
    }
    .choose-items li .icon-wrpr {
        width: 30px;
        height: 30px;
        margin-right: 20px;
    }
    .choose-items li .icon-wrpr svg {
        width: 30px;
        height: 30px;
    }
    .choose-items li .icon-wrpr svg * {
        stroke-width: 1.5;
    }
    .section-title-md {
        font-size: 32px;
        line-height: 44px;
        margin-bottom: 30px;
    }
    .about-us-first-section:before {
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.60));
    }
    .about-us-section-five h3 {
        font-size: 36px;
        line-height: 48px;
    }
    .about-us-section-five p {
        font-size: 14px;
        line-height: 18px;
    }
    .inputs-two-cols .form-group {
        width: 100%;
    }
    .about-us-section-four h3 {
        font-size: 36px;
        line-height: 44px;
    }
    .cookie-policy-modal .btns-wrpr {
        flex-direction: column;
    }
    .cookie-policy-modal .btns-wrpr .btn {
        margin-left: 0;
    }
    .cookie-policy-modal .cookie-policy-customize-btn.btn {
        margin: 0;
        width: 100%;
    }
    .cookie-policy-modal .btns-wrpr .btn+.btn {
        margin-top: 10px;
    }

    .cookie-policy-modal {
        max-width: 100%;
        left: 20px;
    }

    .home-six-section {
        background-attachment: inherit;
        overflow: hidden;
    }

    .home-six-section img {
        display: block!important;
        position: absolute;
        inset: 0px;
        width: 100%;
        height: 100vh;
        z-index: 0;
    }
}

@media(max-width: 400px){
	.first-screen-footer svg {
        bottom: 140px;
    }
}