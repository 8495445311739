.items-center {
    align-items: center;
}
.flex {
    display: flex;
}
.mr-\[10px\] {
    margin-right: 10px;
}

.select-languages .choices__list--dropdown{
    min-width: 237px;
}

.choices__list--dropdown .choices__item--selectable, 
.choices__list[aria-expanded] .choices__item--selectable{
    padding-right: 34px;
}

/* .choices__list--dropdown .choices__item--selectable.is-highlighted::after,  */
.choices__list[aria-expanded] .choices__item--selectable::after {
    content: "";
}
.choices__list[aria-expanded] .choices__item--selectable.choices__item.is-selected::after{
    content: url(../img/icon-done-check.svg);
    opacity: 1;
    width: 14px;
    height: 14px;
}
.choices__list[aria-expanded] .choices__item--selectable.choices__item.is-selected,
.choices__list--dropdown .choices__item--selectable.is-highlighted, 
.choices__list[aria-expanded] .choices__item--selectable.is-highlighted{
    border-radius: 6px;
    background-color: #F1F4F6;
}  

.choices__list--dropdown, 
.choices__list[aria-expanded]{
    border-radius: 12px;
    background-color: #FFF;
    box-shadow: 0px 4px 8px 0px rgba(91, 104, 113, 0.24), 0px 0px 1px 0px rgba(26, 32, 36, 0.32);
    border: none;
    margin-top: 6px;
    padding: 4px;
}

.choices__list--dropdown .choices__item, 
.choices__list[aria-expanded] .choices__item{
    color: #194561;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Avenir Next'
}

.choices[data-type*=select-one]::after{
    width: 14px;
    height: 14px;
    background-image: url(../img/icon-arrow.svg);
    background-position: center;
    background-size: 14px;
    border: none;
    margin-top: 0;
    right: 14px;
    transform: rotate(180deg) translateY(50%);
}

.choices[data-type*=select-one].is-open::after{
    border: none;
    margin-top: 0;
    transform: rotate(0deg) translateY(-50%);
}
.is-open.choices__inner, 
.is-focused .choices__inner, 
.choices__inner{
    border-radius: 360px;
    border: 1px solid #174360;
    padding: 7.5px 7.5px 3.75px;
    background-color: #fff;
}

.choices__list--single{
    font-family: 'Avenir Next';
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #194561;
    padding-right: 24px;
}

.choices__list--dropdown .choices__item, 
.choices__list[aria-expanded] .choices__item{
    font-weight: 500;
    padding: 11px 12px;
}

.select-languages{
    min-width: 70px;
}

.choices__list--single .choices__item {
    text-transform: uppercase;
}